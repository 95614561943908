import { useEffect, useState } from "react";
import { api, showError, showSuccess, showInfo, showLoading } from "../../sharedInterfaces";
import Dialog from '../../comps/dialog/Dialog';
import i18n from '../../i18n/I18n';
import { IBareMetal } from "../../apitypes";
import { RxPencil1 } from 'react-icons/rx';

interface IEditBaremetalProps {
  editBaremetal: IBareMetal,
  onCancel: Function,
  onSave: Function
}

export default function EditBaremetalForm(props: IEditBaremetalProps) {
  const [editBaremetal, setEditBarmetal] = useState<IBareMetal | null>(null);

  useEffect(() => {
    setEditBarmetal(props.editBaremetal)
  }, [])

  if (editBaremetal === null) {
    return null;
  }


  return <Dialog
    title={i18n.s("editBaremetal")}
    showOkButton={true}
    showCancelButton={true}
    showCloseButton={false}
    onCancel={() => {
      props.onCancel();
    }}
    onOK={async () => {
      if (editBaremetal.domain.trim() === "") {
        showError(i18n.s("noDomainSet"))
        return;
      }

      if (editBaremetal.id === null) {
        if (await api.baremetal.createBareMetal(editBaremetal)) {
          showSuccess(i18n.s("baremetalCreated"));
          props.onSave();
        }
        else {
          showError(i18n.s("bareMetalCreateError"))
        }
      }
      else {
        if (await api.baremetal.updateBareMetal(editBaremetal.id, editBaremetal)) {
          showSuccess(i18n.s("baremetalUpdated"));
          props.onSave();
        } else {
          showError(i18n.s("bareMetalUpdateError"))
        }
      }
    }}
  >
    <div className='edit-object'>
      <div className='edit-object-form'>
        <div className='row input-with-label'>
          <div><RxPencil1 /> <label>{i18n.s('domain')}</label></div>
          <input autoComplete='off' type="text" value={editBaremetal.domain} placeholder={i18n.s("domain")} onChange={(e) => {
            setEditBarmetal({
              ...editBaremetal,
              domain: e.currentTarget.value
            });
          }} />
        </div>
        <div className='row input-with-label'>
          <div><RxPencil1 /> <label>{i18n.s('nodeName')}</label></div>
          <input autoComplete='off' type="text" value={editBaremetal.node} placeholder={i18n.s("nodeName")} onChange={(e) => {
            setEditBarmetal({
              ...editBaremetal,
              node: e.currentTarget.value
            });
          }} />
        </div>
        <div className='row input-with-label'>
          <div><RxPencil1 /> <label>{i18n.s('description')}</label></div>
          <input autoComplete='off' type="text" value={editBaremetal.description} placeholder={i18n.s("description")} onChange={(e) => {
            setEditBarmetal({
              ...editBaremetal,
              description: e.currentTarget.value
            });
          }} />
        </div>
        <div className='row input-with-label'>
          <div><RxPencil1 /> <label>{i18n.s('bareMetalFull')}</label></div>
          <select value={editBaremetal.full ? 1 : 0} onChange={(e) => {
            setEditBarmetal({
              ...editBaremetal,
              full: parseInt(e.currentTarget.value) === 1 ? true : false
            });
          }}>
            <option value={0}>{i18n.s('no')}</option>
            <option value={1}>{i18n.s('yes')}</option>
          </select>
        </div>
        <div className='row input-with-label'>
          <div><RxPencil1 /> <label>{i18n.s('sshPort')}</label></div>
          <input autoComplete='off' type="number" min={0} max={65535} value={editBaremetal.ssh} placeholder={i18n.s("sshPort")} onChange={(e) => {
            setEditBarmetal({
              ...editBaremetal,
              ssh: parseInt(e.currentTarget.value)
            });
          }} />
        </div>
        <div className='row input-with-label'>
          <div><RxPencil1 /> <label>{i18n.s('httpPort')}</label></div>
          <input autoComplete='off' type="number" min={0} max={65535} value={editBaremetal.http} placeholder={i18n.s("httpPort")} onChange={(e) => {
            setEditBarmetal({
              ...editBaremetal,
              http: parseInt(e.currentTarget.value)
            });
          }} />
        </div>
        <div className='row input-with-label'>
          <div><RxPencil1 /> <label>{i18n.s('httpsPort')}</label></div>
          <input autoComplete='off' type="number" min={0} max={65535} value={editBaremetal.https} placeholder={i18n.s("httpsPort")} onChange={(e) => {
            setEditBarmetal({
              ...editBaremetal,
              https: parseInt(e.currentTarget.value)
            });
          }} />
        </div>
      </div>
    </div>
  </Dialog>
}