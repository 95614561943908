export async function findPappersCompany(siret: string): Promise<any | null> {
  //https://api.pappers.fr/v2/recherche?api_token=93985a5fd7b613126056824dee42e8cb1e81b68519fd9ff5&bases=entreprises&siege=true&entreprise_cessee=false&siret=752991554
  const response = await fetch(`https://api.pappers.fr/v2/recherche?api_token=93985a5fd7b613126056824dee42e8cb1e81b68519fd9ff5&bases=entreprises&siege=true&siret=${siret}`, {
    method: "GET"
  });
  if (response.status !== 200) {
    console.error(`Failed to search in Pappers.fr API : ${response.status}`);
    return null; //impossible de chercher. crédit dépassé ?
  }
  const results = await response.json();
  if (results.resultats.length === 1) {
    return results.resultats[0];
  }
  return null; //trop ou pas assez de résultats
}


export async function openPappersCompany(siretOrSiren: string): Promise<any | null> {
  if (siretOrSiren.length !== 9 && siretOrSiren.length !== 14) {
    return null;
  }

  const type = siretOrSiren.length === 14 ? "siret" : "siren";
  const response = await fetch(`https://api.pappers.fr/v2/entreprise?api_token=93985a5fd7b613126056824dee42e8cb1e81b68519fd9ff5&${type}=${siretOrSiren}`, {
    method: "GET"
  });
  if (response.status !== 200) {
    console.error(`Failed to search in Pappers.fr API : ${response.status}`);
    return null; //impossible de chercher. crédit dépassé ?
  }
  const result = await response.json();
  if (result) {
    return result;
  }
  return null; //trop ou pas assez de résultats
}


export async function getAPICredits(): Promise<number | null> {
  const response = await fetch(`https://api.pappers.fr/v2/suivi-jetons?api_token=93985a5fd7b613126056824dee42e8cb1e81b68519fd9ff5`, {
    method: "GET"
  });
  if (response.status !== 200) {
    console.error(`Failed to search in Pappers.fr API : ${response.status}`);
    return null;
  }
  const result = await response.json();
  if (result) {
    return (result.jetons_abonnement + result.jetons_pay_as_you_go_restants) - result.jetons_abonnement_utilises;
  }
  return null;
}