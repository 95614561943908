import { GetAPI, PostAPI, PutAPI } from './http';
import { IXorcomLicense } from '../../apitypes/index';

export default class XorcomLicensesModule {
  public baseUri: string;
  public token: string;
  public onAuthErrorCallback: Function | null = null;

  constructor(baseUri: string, token: string, onAuthErrorCallback: Function | null) {
    this.baseUri = baseUri;
    this.token = token;
    this.onAuthErrorCallback = onAuthErrorCallback;
  }

  setToken(token: string) {
    this.token = token;
  }


  async getLicenses(): Promise<IXorcomLicense[]> {
    let response = await GetAPI(`${this.baseUri}/xorcomlicenses`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return [];
    }
  }


  async getLicense(key: string): Promise<IXorcomLicense | null> {
    //key might contain "/" so we need to escape the uri.
    const escapedKey = encodeURIComponent(key);
    let response = await GetAPI(`${this.baseUri}/xorcomlicenses/${escapedKey}`, this.token, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }


  async setLicenseKeyOwner(key: string, reseller: number | null, customer: number | null): Promise<boolean> {
    const escapedKey = encodeURIComponent(key);
    let response = await PutAPI(`${this.baseUri}/xorcomlicenses/setkeyowner/${escapedKey}`, this.token, { reseller, customer }, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return true;
    }
    else {
      console.error(msg.error);
      return false;
    }
  }

  async setLicenseSystemOwner(system: string, reseller: number | null, customer: number | null): Promise<boolean> {
    const escapedSystem = encodeURIComponent(system);
    let response = await PutAPI(`${this.baseUri}/xorcomlicenses/setsystemowner/${escapedSystem}`, this.token, { reseller, customer }, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return true;
    }
    else {
      console.error(msg.error);
      return false;
    }
  }


  async createLicenseKey(reseller: number | null, customer: number | null, product: string, type: string, attributes: any, comment: string, validFor: number): Promise<string | null> {
    let response = await PostAPI(`${this.baseUri}/xorcomlicenses`, this.token, { reseller, customer, product, type, attributes, comment, validFor }, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return msg.data;
    }
    else {
      console.error(msg.error);
      return null;
    }
  }


  async sendRenewMail(license: IXorcomLicense, recipients: string[]): Promise<boolean> {
    let response = await PostAPI(`${this.baseUri}/xorcomlicenses/renewmail`, this.token, { license, recipients }, this.onAuthErrorCallback);
    const msg = await response.json();
    if (msg.status === "success") {
      return true;
    }
    else {
      console.error(msg.error);
      return false;
    }
  }

}




