export enum EPrivileges {
  SUPER_ADMIN = 'SUPER_ADMIN',
  READ_RESELLERS = "READ_RESELLERS",
  WRITE_RESELLERS = "WRITE_RESELLERS",
  READ_USERS = 'READ_USERS',
  WRITE_USERS = 'WRITE_USERS',
  READ_PRODUCTS = "READ_PRODUCTS",
  WRITE_PRODUCTS = "WRITE_PRODUCTS",
  READ_PRICELIST = "READ_PRICELIST",
  WRITE_PRICELIST = "WRITE_PRICELIST",
  READ_CUSTOMERS = "READ_CUSTOMERS",
  WRITE_CUSTOMERS = "WRITE_CUSTOMERS",
  READ_SUBSCRIPTIONS = "READ_SUBSCRIPTIONS",
  WRITE_SUBSCRIPTIONS = "WRITE_SUBSCRIPTIONS",
  CHANGE_SUBSCRIPTION_PRICE = "CHANGE_SUBSCRIPTION_PRICE",
  VALIDATE_SUBSCRIPTION = "VALIDATE_SUBSCRIPTION",
  SET_SUBSCRIPTION_STEPS = "SET_SUBSCRIPTION_STEPS",
  READ_FILES = "READ_FILES",
  WRITE_FILES = "WRITE_FILES",
  ELIGIBILITY = "ELIGIBILITY",
  READ_XORCOM_LICENSES = "READ_XORCOM_LICENSES",
  WRITE_XORCOM_LICENSES = "WRITE_XORCOM_LICENSES",
  READ_BAREMETAL = "READ_BAREMETAL",
  WRITE_BAREMETAL = "WRITE_BAREMETAL",
  READ_VMS = "READ_VMS",
  WRITE_VMS = "WRITE_VMS"
}

export enum ENotifications {
  USER_CREATED = "USER_CREATED",
  USER_MODIFIED = "USER_MODIFIED",
  USER_DELETED = "USER_DELETED",
  RESELLER_CREATED = "RESELLER_CREATED",
  RESELLER_MODIFIED = "RESELLER_MODIFIED",
  RESELLER_DELETED = "RESELLER_DELETED",
  PRODUCT_CREATED = "PRODUCT_CREATED",
  PRODUCT_MODIFIED = "PRODUCT_MODIFIED",
  PRODUCT_DELETED = "PRODUCT_DELETED",
  CUSTOMER_CREATED = "CUSTOMER_CREATED",
  CUSTOMER_MODIFIED = "CUSTOMER_MODIFIED",
  CUSTOMER_DELETED = "CUSTOMER_DELETED",
  PRICELIST_CREATED = "PRICELIST_CREATED",
  PRICELIST_MODIFIED = "PRICELIST_MODIFIED",
  PRICELIST_DELETED = "PRICELIST_DELETED",
  PRICE_MODIFIED = "PRICE_MODIFIED",
  SUBSCRIPTION_VALIDATED = "SUBSCRIPTION_VALIDATED",
  SUBSCRIPTION_CANCELED = "SUBSCRIPTION_CANCELED",
  CGV_MODIFIED = "CGV_MODIFIED",
  CGV_DELETED = "CGV_DELETED",
  BAREMETAL_CREATED = "BAREMETAL_CREATED",
  BAREMETAL_MODIFIED = "BAREMETAL_MODIFIED",
  BAREMETAL_DELETED = "BAREMETAL_DELETED",
  VM_CREATED = "VM_CREATED",
  VM_MODIFIED = "VM_MODIFIED",
  VM_DELETED = "VM_DELETED",
  DHCP_UPDATED = "DHCP_UPDATED",
  FILE_CREATED = "FILE_CREATED",
  FILE_MODIFIED = "FILE_MODIFIED",
  FILE_DELETED = "FILE_DELETED",
  FILE_UPLOADED = "FILE_UPLOADED",
  XORCOM_LICENSE_OWNER_CHANGED = "XORCOM_LICENSE_OWNER_CHANGED",
  XORCOM_LICENSE_CREATED = "XORCOM_LICENSE_CREATED"
}


export interface IXorcomLicense {
  key: string,
  reseller: number | null,
  customer: number | null,
  product: string,
  system: string,
  type: string,
  created: number,
  active: number,
  comment: string,
  attributes: any,
  ownedBy: string,
  registered: number,
  firstRegistration: number,
  validAfter: number,
  validFor: number,
  validUntil: number,
  lastMail: number
}


export interface IImmeuble {
  long: number,
  lat: number,
  imbID: string,
  numVoie: string,
  cpNoVoie: string,
  typeVoie: string,
  nomVoie: string,
  batiment: string,
  codeInsee: string,
  codePostal: string,
  commune: string,
  catalogLocImb: string,
  imbEtat: string,
  pmRef: string,
  pmEtat: string,
  codeL331: string,
  geomMod: string,
  typeImb: string,
  dateCompletude: string,
  dateCompletudeManquante: string
}

export interface ICommune {
  commune: string,
  codePostal: string
}

export interface IAdresseCommune {
  x: number,
  y: number,
  numVoie: string,
  cpNoVoie: string,
  typeVoie: string,
  nomVoie: string,
  codePostal: string,
  commune: string
}



export interface ISubscriptionStep {
  subscription: string,
  product: string | null,
  step: string,
  qty: number,
  done: number,
  notes: string,
  user?: number | null,
  username?: string
}



export interface ICGV {
  id: string,
  title: string,
  content: string
}

export interface ICustomer {
  id: number,
  reseller: number,
  company: string,
  address1: string,
  address2: string,
  postalCode: string,
  city: string,
  country: string,
  contact: string,
  email: string,
  tel: string,
  siret: string,
  tvaintracom: string,
  naf: string,
  openSubscriptionCount?: number
  closedSubscriptionCount?: number
}

export interface IDHCPEntry {
  mac: string,
  ip: string,
  status: string,
  hostname: string,
  dynamic: boolean,
  comment: string,
  expire: number,
  lastSeen: number,
  vendor: string
}

export interface IFile {
  id: string,
  name: string,
  path: string,
  isDirectory: boolean,
  size?: number,
  dateModification: number,
  parent: string | null,
  childrens?: Array<IFile>,
}

export interface IPricelist {
  id: number,
  name: string
}


export interface IAttachement {
  fileName: string,
  datauri: string,
}

export interface IPrice {
  product: string,
  pu: number,
  fas: number,
  engagement: number,
  com: number
}

export interface IProduct {
  ref: string,
  name: string,
  noteAuto: string,
  enabled: boolean,
  steps: Array<string>,
  used?: number
}

export interface IReseller {
  id: number,
  company: string,
  siret: string,
  address1: string,
  address2: string,
  postalCode: string,
  city: string,
  country: string,
  contact: string,
  email: string,
  tel: string,
  tvaintracom: string,
  naf: string,
  pricelists: number[],
  customersCount?: number
}

export interface ISubscription {
  id: string,
  customer: number,
  date: number,
  observations: string[],
  pricelist: number,
  validated: number,
  canceled: number,
  contractFile: string | null
  mandatPorta: string | null,
  ribFile: string | null
  billed: number,
  alert: string[],
  invoiceReseller: boolean,
  stepsToDo?: number
}

export interface ISubscriptionLine {
  id: string,
  subscription: string,
  product: string,
  startTime: number,
  endTime: number,
  fas: number,
  qty: number,
  pu: number,
  engagement: number
}

export interface IUser {
  id: number,
  email: string,
  reseller: number,
  password?: string,
  tempAccessToken?: string,
  token?: string,
  lastSeen?: number,
  cgvSeen: number | null,
  privileges: Array<EPrivileges>,
  notifications: Array<ENotifications>
}


export interface ITask {
  id: number,
  date: number,
  user: number,
  action: string,
  data?: string,
  done: number,
  success: boolean,
  error?: string | undefined
}




/*
 * Proxmox Stuff :)
 */

export interface IBareMetal {
  id: number | null,
  domain: string,
  node: string,
  description: string,
  full: boolean,
  ssh: number,
  http: number,
  https: number
}

export interface IProxmoxTaskResult {
  success: boolean,
  upid: string,
  log: string
}

/*
 * End of Proxmox Stuff
 */



export function hasAnyPrivileges(user: IUser | null, requiredPrivileges: EPrivileges | Array<EPrivileges>): boolean {
  if (user === null) {
    console.warn(`Failed to check any privileges: user is null`);
    return false;
  }
  const pri = Array.isArray(requiredPrivileges) ? requiredPrivileges : [requiredPrivileges];
  for (const ep of pri) {
    if (user.privileges.includes(ep)) {
      return true;
    }
  }
  //console.warn(`hasAnyPrivileges: user ${user.id} miss any of these privileges: `, requiredPrivileges);
  return false;
}

export function hasAllPrivileges(user: IUser | null, requiredPrivileges: EPrivileges | Array<EPrivileges>): boolean {
  console.log("hasAllPrivileges", user, requiredPrivileges);
  if (user === null) {
    console.warn(`Failed to check any privileges: user is null`);
    return false;
  }
  const pri = Array.isArray(requiredPrivileges) ? requiredPrivileges : [requiredPrivileges];
  let hasAll = false;
  for (const ep of pri) {
    if (user.privileges.includes(ep) === false) {
      return false; //if any privilege is missing, return immediatly false
    } else {
      hasAll = true; //set to true as we found at least 1 privilege
    }
  }
  if (!hasAll) {
    //console.warn(`hasAllPrivileges: user ${user.id} miss all of these privileges: `, requiredPrivileges);
  }
  return hasAll; //Return false if no privilege found or true if all privileges found
}


