import React, { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import { IPageProps, E_SORTORDER, sortObjects } from '../../sharedInterfaces';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import Dialog from '../../comps/dialog/Dialog';
import { IReseller, IPricelist } from '../../apitypes/index';
import EditResellerForm from './EditResellerForm';

import { BsPlus } from 'react-icons/bs';
import { VscSearch, VscRefresh, VscEdit } from 'react-icons/vsc';
import { AiOutlineDelete } from 'react-icons/ai';

interface IState {
  search: string,
  resellers: IReseller[],
  pricelists: IPricelist[],
  showDeleteDialog: boolean,
  editObject: IReseller | null,
  createNew: boolean,
  selectedIDs: number[],
  sortBy: string,
  sortOrder: E_SORTORDER,
}

class ResellerManagement extends Component<IPageProps, IState> {
  constructor(props: IPageProps) {
    super(props);

    this.state = {
      search: '',
      resellers: [],
      pricelists: [],
      showDeleteDialog: false,
      editObject: null,
      createNew: false,
      selectedIDs: [],
      sortBy: '',
      sortOrder: E_SORTORDER.ASC,
    }
  }

  async componentDidMount(): Promise<void> {
    this.reloadObjects();
  }

  sortObjectsBy(sortBy: string) {
    const { resellers, sortOrder } = this.state;
    let newSortOrder: E_SORTORDER = E_SORTORDER.ASC;
    if (sortBy === this.state.sortBy) {
      //Change sort order
      if (sortOrder === E_SORTORDER.ASC) {
        newSortOrder = E_SORTORDER.DESC;
      }
    }
    console.log(`Sort ${sortBy} ${newSortOrder}`);

    this.setState({
      sortOrder: newSortOrder,
      sortBy: sortBy,
      resellers: sortObjects(resellers, sortBy, newSortOrder)
    });
  }

  async reloadObjects() {

    showLoading(true);
    this.setState({
      resellers: await api.reseller.getResellers(),
      pricelists: await api.pricelist.getPricelists(),
      editObject: null,
      createNew: false,
      showDeleteDialog: false,
      selectedIDs: [],
    });
    showLoading(false);
  }



  renderDeleteDialog() {
    const { selectedIDs } = this.state;
    return <Dialog
      title={i18n.s("deleteResellerConfirm")}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        this.setState({
          showDeleteDialog: false
        })
      }}
      onOK={async () => {
        showLoading(true);
        for (var id of selectedIDs) {
          if (await api.reseller.deleteReseller(id) === false) {
            showError(i18n.s('error'));
          }
        }
        this.reloadObjects();
        showLoading(false);
      }}
    >
      <div>
        <label>{i18n.s("deleteResellerConfirmString", [selectedIDs.length.toString()])}</label>
      </div>
    </Dialog>
  }


  renderObjectRow(obj: IReseller): React.ReactNode {
    const { selectedIDs } = this.state;

    const isSelected = selectedIDs.findIndex(suid => suid === obj.id) > -1;
    const search = this.state.search.trim().toUpperCase();
    if (search !== "") {
      let doNoDisplay = true;
      if (obj.company.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (doNoDisplay) {
        if (selectedIDs.indexOf(obj.id) !== -1) {
          this.setState({
            selectedIDs: selectedIDs.filter(suid => suid !== obj.id)
          });
        }
        return null;
      }
    }


    return <tr key={obj.id} >
      <td style={{ textAlign: "center" }}>
        <input
          type="checkbox"
          disabled={obj.customersCount && obj.customersCount > 0 ? true : undefined}
          checked={isSelected}
          onChange={(e) => {
            if (e.currentTarget.checked) {
              this.setState({
                selectedIDs: [
                  ...selectedIDs,
                  obj.id
                ]
              })
            }
            else {
              this.setState({
                selectedIDs: selectedIDs.filter(suid => suid !== obj.id)
              })
            }
          }}
        />
      </td>
      <td><button onClick={() => { this.setState({ editObject: obj }) }}><VscEdit /></button></td>
      <td>
        {
          obj.siret && obj.siret.length >= 9 ? <a target='_blank' rel="noreferrer" href={`https://www.pappers.fr/entreprise/${obj.siret.substring(0, 9)}`}>{obj.company}</a> : obj.company
        }
      </td>
      <td>{obj.siret}</td>
      <td>{obj.postalCode}</td>
      <td>{obj.city}</td>
      <td>{obj.country}</td>
      <td>{obj.contact}</td>
      <td>{obj.email}</td>
      <td>{obj.tel}</td>
      <td>{obj.customersCount}</td>
    </tr>
  }





  render() {
    const { resellers, search, editObject, createNew, selectedIDs, showDeleteDialog } = this.state;

    return (
      <Fragment>
        <div className='management'>
          <h1>{i18n.s("resellers")}</h1>
          <div className='search'>
            <div className='input-with-label'>
              <div><VscSearch /></div>
              <input type="text" value={search} placeholder={i18n.s('search')} onChange={(e) => { this.setState({ search: e.currentTarget.value }) }} />
            </div>
          </div>

          <div className='button-actions'>
            <button onClick={() => { this.reloadObjects() }} title={i18n.s("reload")}><VscRefresh /></button>
            <button className='success' onClick={() => { this.setState({ editObject: null, createNew: true }) }} title={i18n.s("createReseller")}><BsPlus /></button>
            <button className='danger' disabled={selectedIDs.length === 0} title={i18n.s("deleteReseller")} onClick={() => {
              this.setState({
                showDeleteDialog: true
              })
            }}><AiOutlineDelete /></button>
          </div>

          <div>
            <table>
              <thead>
                <tr>
                  <th style={{ width: "30px", textAlign: "center" }}>
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        if (e.currentTarget.checked) {
                          this.setState({
                            selectedIDs: resellers.map(p => p.id)
                          })
                        }
                        else {
                          this.setState({
                            selectedIDs: []
                          })
                        }
                      }}
                    />
                  </th>
                  <th style={{ width: "30px", textAlign: "center" }}></th>
                  <th onClick={() => { this.sortObjectsBy("company") }}>{i18n.s("company")}</th>
                  <th onClick={() => { this.sortObjectsBy("siret") }}>{i18n.s("SIRET")}</th>
                  <th onClick={() => { this.sortObjectsBy("postalCode") }}>{i18n.s("postalCode")}</th>
                  <th onClick={() => { this.sortObjectsBy("city") }}>{i18n.s("city")}</th>
                  <th onClick={() => { this.sortObjectsBy("country") }}>{i18n.s("country")}</th>
                  <th onClick={() => { this.sortObjectsBy("contact") }}>{i18n.s("contact")}</th>
                  <th onClick={() => { this.sortObjectsBy("email") }}>{i18n.s("email")}</th>
                  <th onClick={() => { this.sortObjectsBy("tel") }}>{i18n.s("tel")}</th>
                  <th onClick={() => { this.sortObjectsBy("customersCount") }}>{i18n.s("Clients")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  resellers.map((obj) => {
                    return this.renderObjectRow(obj)
                  })
                }
              </tbody>
            </table>
          </div>

          {
            editObject || createNew ? <EditResellerForm editResellerID={editObject ? editObject.id : null} onCancel={() => { this.setState({ editObject: null, createNew: false }) }} onSave={() => { this.reloadObjects() }} /> : null
          }

          {
            showDeleteDialog ? this.renderDeleteDialog() : null
          }

        </div>
      </Fragment>

    );
  }
}

export default ResellerManagement;